<template>
  <!-- LISTADO DE TEMAS -->
  <v-list :class="listClasses">
    <v-subheader v-if="headerText !== ''">{{ headerText }}</v-subheader>
    <v-list-item
      v-for="item in items"
      :key="item[keys.id]"
      link
      @click="$emit('select-item', item)"
      two-line
    >
      <v-list-item-icon align-self="center">
        <v-icon>mdi-bookmark</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">{{
          item[keys.name]
        }}</v-list-item-title>
        <!-- <v-list-item-subtitle>{{ item[keys.domain] }}</v-list-item-subtitle> -->
      </v-list-item-content>

      <!-- <v-list-item-avatar>
    <v-img :src="''"></v-img>
    </v-list-item-avatar> -->
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "BasicListComponent",
  props: {
    headerText: {
      type: String,
      default: "",
    },
    listClasses: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style></style>
